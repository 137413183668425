import { graphql } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { Event } from "../components/community/Event"
import { getEvents } from "../components/utils/Event"

// props
interface Props {
  data: {
    events: {
      nodes: Array<{
        title: string
        link: string
        date: string
        location: string
      }>
    }
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
      }
    }
  }
}

// markup
const EventsTemplate = (props: Props) => {
  const events = getEvents(props.data.events.nodes as any)
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="events"
    >
      <div className="container my-6">
        <HTMLContent
          content={props.data.page.html}
          className={"markdown hero"}
        ></HTMLContent>
      </div>
      <div className="has-background-light pt-2">
        <div className="container py-6">
          <div className="columns is-space-between is-multiline">
            {events.map((event, i) => (
              <Event key={`event${i}`} event={event} />
            ))}
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default EventsTemplate

// graphQL queries
export const pageQuery = graphql`
  query EventsTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
      }
    }
    events: allContentfulEvent(sort: { order: ASC, fields: date }) {
      nodes {
        title
        link
        date(formatString: "MMMM DD, YYYY")
        location
      }
    }
  }
`
